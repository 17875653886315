import React from "react";

function Footer(props){
    const year = new Date().getFullYear();
    return(
        <div className="footer">
             <p className="footer__copyright-text">{"Copyright © "+year+" Noah Gwynn. All Rights Reserved"}</p>
             <a href="/privacy">Privacy Policy</a>
        </div>
       
    )
}

export default Footer;