import React from "react";

import Header from "./Home/Header/App-Header.jsx";
import InfoSection from "./Home/Info/App-Info.jsx";
import Work from "./Home/Work/App-Work.jsx";
import Tech from "./Home/Tech/App-Tech.jsx";
import Contact from "./Home/Contact/App-Contact.jsx";
import Testimonials from "./Home/Testimonials/App-Testimonials.jsx";
import Nav from "./Nav.jsx";
import NavMobile from "./NavMobile.jsx";
import Footer from "./Footer/Footer.jsx";
import Share from './Share-Dropdown.jsx'
import MessengerCustomerChat from 'react-messenger-customer-chat';


function HomePage() {
    //Google Analytics
    window.gtag('config', 'G-HSTP7R42LV', {
        page_title: document.title, page_path: window.location.pathname +
            window.location.search
    });
    console.log('Logged entry to ' + window.location);


    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 900;

    React.useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    return (<div>

            <Share/>
            <MessengerCustomerChat
                pageId="105316294766767"
                appId="226637258894477"
                themeColor="#2A4849"
                greetingDialogDisplay="hide"
                loggedInGreeting="Hello, have any questions? Send me a message and I'll get right back to you."
                loggedOutGreeting="Hello, have any questions? Send me a message and I'll get right back to you."
            />

            {width < breakpoint ?
                <div style={{height: "100%"}}>
                    <NavMobile/>

                </div>
                :
                <Nav width={width} breakpoint={breakpoint}/>}

            <Header/>
            <hr id="about"/>
            <InfoSection/>
            <hr id="work"/>
            <Work/>
            <hr id="technologies"/>
            <Tech/>
            <hr id="testimonials"/>
            <Testimonials/>
            <hr id="contact"/>
            <Contact/>

            <span className="page-bottom__social-btns">
        <div className="fb-like" data-href="https://www.facebook.com/noahgwynn.io" data-width=""
             data-layout="button_count" data-action="like" data-size="large" data-share="false"/>
        <a target="_blank" rel="noopener noreferrer" className="twitter-btn twitter-follow-button"
           title="Follow @GwynnNoah on Twitter" href="https://twitter.com/GwynnNoah"><i></i><span
            className="label">Follow</span></a>
      </span>

            <Footer/>
        </div>

    )
}

export default HomePage;