import React from "react";

function Pattern({colour, delay, duration}) {
    return <div className="pattern">
        <div className="pattern_wrap pattern_wrap-1">
            <div className="pattern_line pattern_1"
                 style={{backgroundColor: colour, animationDelay: delay, animationDuration: duration}}>&nbsp;</div>
        </div>
        <div className="pattern_wrap pattern_wrap-2">
            <div className="pattern_line pattern_2"
                 style={{backgroundColor: colour, animationDelay: delay, animationDuration: duration}}>&nbsp;</div>
        </div>
        <div className="pattern_wrap pattern_wrap-3">
            <div className="pattern_line pattern_3"
                 style={{backgroundColor: colour, animationDelay: delay, animationDuration: duration}}>&nbsp;</div>
        </div>
        <div className="pattern_wrap pattern_wrap-4">
            <div className="pattern_line pattern_4"
                 style={{backgroundColor: colour, animationDelay: delay, animationDuration: duration}}>&nbsp;</div>
        </div>
    </div>

    // return <div className="pattern">
    //     <div className="pattern_wrap pattern_wrap-1">
    //     <div className="pattern_line pattern_1" style={{animationPlayState: "paused", backgroundColor:colour}}>&nbsp;</div>
    //     </div>
    //     <div className="pattern_wrap pattern_wrap-2">
    //     <div className="pattern_line pattern_2" style={{animationPlayState: "paused", backgroundColor:colour}}>&nbsp;</div>
    //     </div>
    //     <div className="pattern_wrap pattern_wrap-3">
    //     <div className="pattern_line pattern_3" style={{animationPlayState: "paused", backgroundColor:colour}}>&nbsp;</div>
    //     </div>
    //     <div className="pattern_wrap pattern_wrap-4">
    //     <div className="pattern_line pattern_4" style={{animationPlayState: "paused", backgroundColor:colour}}>&nbsp;</div>
    //     </div>
    // </div>

}

export default Pattern;