import React from "react";
import SubtitleH2 from "../../SubTitle-H2.jsx";
import Paragraph from "../../Paragraph.jsx";

function Testimonials(){
    return (
        <div className="testimonial">
            <div style={{marginBottom:"1rem"}} className="subtitle-border"><SubtitleH2 text="Testimonial" /></div>
            
            <Paragraph text="Our new website is just what we wanted! Noah was always friendly and helpful, and he created a website that’s clean and responsive, customer-friendly and easy for us to manage. " /> 
            <Paragraph text="- Ben Crick, Artistic Director, Skipton Camerata" />

        </div>
    )
}

export default Testimonials;