import React from "react";
import Svg from "../../Svg.jsx";
import techCards from "./techCards.js"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CardButtons(props){


let techSettings = {
    arrows: false,
    infinite: true,
    rows: 2,
    slidesToScroll: 1,
    slidesToShow: 6,
    swipeToSlide: true,
    speed: 3500,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          }
        }
      ]

  };

    return (
        
            <div  className="tech__cards" >
            <Slider {...techSettings}>
                {techCards.map((card, index)=>{
                        const name = Object.values(card)[0]
                        const icon = Object.values(card)[1]
                        const info = Object.values(card)[2]

                        return <div key={index} onMouseEnter={()=>{props.handleInfo(info)}} onFocus={()=>{props.handleInfo(info)}} className="tech__cards--card">
                        <Svg name={icon} />
                        <h4>{name}</h4>
                        </div>
                })}
            </Slider>
            </div>
        
    )
}

export default CardButtons;