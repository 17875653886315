import React from "react";

function Privacy(){
    //Google Analytics
    window.gtag('config', 'G-HSTP7R42LV', { page_title: document.title, page_path: window.location.pathname +
    window.location.search }); console.log('Logged entry to ' + window.location);


    const year = new Date().getFullYear();
    return ( 
        <div className="privacy-policy">
            <a className="privacy-policy__return" href="https://www.noahgwynn.io/">&larr; Return to home page</a>
            <br/><br/><br/>
            <h1>Privacy Policy for noahgwynn.io</h1>
            <p>At noahgwynn.io, accessible from<a href="https://www.noahgwynn.io/"> https://www.noahgwynn.io/</a>, one of my main priorities is the privacy of my visitors. This Privacy Policy document contains types of information that is collected and recorded by noahgwynn.io and how it is used.</p>
            <p>If you have additional questions or require more information about my Privacy Policy, do not hesitate to <a href="https://www.noahgwynn.io/#contact">contact me</a>.</p>
            <p>This Privacy Policy applies only to my online activities and is valid for visitors to my website with regards to the information that they shared and/or collect in noahgwynn.io. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <p>By using my website, you hereby consent to my Privacy Policy</p>
            <h2>Information I collect</h2>
            <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point I ask you to provide your personal information.</p>
            <p>If you contact me directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <h2>How I use your information</h2>
            <p>I use the information I collect in various ways, including to:</p>
            <ul>
            <li>Provide, operate, and maintain my webste</li>
            <li>Improve, personalize, and expand my webste</li>
            <li>Understand and analyze how you use my webste</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you, either directly or through one of my partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
            </ul>
            <h2>Log Files</h2>
            <p>noahgwynn.io follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
            <h2>Cookies and Web Beacons</h2>
            <p>Like any other website, noahgwynn.io uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing my web page content based on visitors' browser type and/or other information.</p>
            <p>For more general information on cookies, please read <a rel="nofollow" href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies"</a>.</p>
            <h2>Third Party Privacy Policies</h2>
            <p>noahgwynn.io's Privacy Policy does not apply to other websites. Thus, I am advising you to consult the respective Privacy Policies of these third-parties for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
            <h2>Data Protection Rights</h2>
            <p>I would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <p>The right to access – You have the right to request copies of your personal data.</p>
            <p>The right to rectification – You have the right to request that I correct any information you believe is inaccurate. You also have the right to request that I complete the information you believe is incomplete.</p>
            <p>The right to erasure – You have the right to request that I erase your personal data, under certain conditions.</p>
            <p>The right to restrict processing – You have the right to request that I restrict the processing of your personal data, under certain conditions.</p>
            <p>The right to object to processing – You have the right to object to my processing of your personal data, under certain conditions.</p>
            <p>The right to data portability – You have the right to request that I transfer the data that I have collected to another organization, or directly to you, under certain conditions.</p>
            <p>If you make a request, I have one month to respond to you. If you would like to exercise any of these rights, please <a href="https://www.noahgwynn.io/#contact">contact me</a>.</p>
            <h2>Children's Information</h2>
            <p>Another part of my priority is adding protection for children while using the internet. I encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
            <p>noahgwynn.io does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on my website, I strongly encourage you to contact me immediately and I will do my best effort to promptly remove such information from my records.</p>
            <br/>
            <a className="privacy-policy__return" href="https://www.noahgwynn.io/">&larr; Return to home page</a>
            <br/><br/><br/>
            <div className="footer">
             <p className="footer__copyright-text">{"Copyright © "+year+" Noah Gwynn. All Rights Reserved"}</p>
             <a href="/privacy">Privacy Policy</a>
        </div>
        </div>
    )
}

export default Privacy;