const workCards = [

    {
        name: "Skipton Camerata",
        info: "Created a new multi-page website with concert and blog management",
        src:"media/skipton-camerata-web.jpg",
        alt: "Skipton Camertata Website",
        href: "https://skiptoncamerata.com"
    },

    {
        name: "Lockdown Diaries",
        info: "Designed an add on page for the Lockdown Diaries Project",
        src:"media/lockdown-diaries-page.jpg",
        alt: "Lockdown Diaries Page",
        href: "https://skiptoncamerata.com/lockdown-diaries"
    }



]

export default workCards;