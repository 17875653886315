import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';


const useStyles = makeStyles({
    social__share: {
        position:"relative",
        fontSize: "4rem",
        padding: "0 .4rem",
        backgroundColor: "#2A4849",
        opacity: ".9",
        color: "#f9ffff",
        transition: ".15s",
        top:"-.2rem",
        right: "0",

        '&:hover':{
            backgroundColor: "#0D5C42",
        }
    },
    social__dropBtns: {
        position:"relative",
        fontSize: "4rem",
        padding: "0 .4rem",
        opacity: ".9",
        transition: ".15s",
        top:"-.2rem",
        right: "0",

        '&:hover':{
            color: "#0D5C42",
            transform: "scale(1.13)",
        }
    },
    fb: {
        color:"#3b5998",
    },
    twitter: {
        color:"#00acee",
    },
    mail: {
        color:"#333",
    },
    
  });

function Share(props){
    const [collapse, setCollapse] = useState(false);

    const classes = useStyles();

    function handleCollapse(params) {
        setCollapse(!collapse)
    }
    return(
        <div>
            <button onClick={handleCollapse} className="btn social__btn"><span style={{position:"absolute", opacity:"0"}}>Menu</span><ShareIcon className={classes.social__share} /></button>

            <div className={collapse ? "uncollapsed" : ""}>
                <div className="social__drop-div collapse">
                    <div data-href="https://www.noahgwynn.io/" data-layout="button" data-size="small"><a title="Share on Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.noahgwynn.io%2F&amp;src=sdkpreparse" className="social__btn--"><FacebookIcon className={`${classes.social__dropBtns} ${classes.fb}`} /></a></div>
                     <div><a title="Share on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Get%20yourself%20a%20beautiful%20websites%20that%20will%20elevate%20your%20business%20with&url=noahgwynn.io"><TwitterIcon className={`${classes.social__dropBtns} ${classes.twitter}`} /></a></div>
                     <div><a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site https://www.noahgwynn.io/."title="Share by Email"><EmailIcon className={`${classes.social__dropBtns} ${classes.mail}`} /></a></div>
                </div>
            </div>
            
    
        </div>
    )
}

export default Share;