import React from "react";
import ImNoah from "./IntroTitles.jsx"
import SubtitleH2 from "../../SubTitle-H2.jsx"
import Pattern from "./Pattern.jsx";

function Home() {
    return <div className="head">

        <Pattern colour="#9CFFFA" duration="30s" delay="-14.3s"/>
        <Pattern colour="#ACF39D" duration="28s" delay="-14.3s"/>
        <Pattern colour="#AF3E4D" duration="29s" delay="-23s"/>
        <Pattern colour="#E3C16F" duration="27s" delay="-19s"/>

        {/*<Pattern colour="#9CFFFA"/>*/}
        {/*<Pattern colour="#ACF39D"/>*/}
        {/*<Pattern colour="#AF3E4D"/>*/}
        {/*<Pattern colour="#E3C16F"/>*/}

        <ImNoah/>
    </div>
}

export default Home;




