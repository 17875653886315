import React from "react";
import SubtitleH2 from "../../SubTitle-H2.jsx";
import WorkCard from "./Work-Card.jsx";

function Work() {
    return (
        <div className="work">
            <div className="subtitle-border"><SubtitleH2 text="My Work"/></div>

            <WorkCard name="skipton-Camerata-Web"/>
        </div>
    )
}

export default Work;