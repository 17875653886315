import React, {useState} from "react";
import Anchor from "./Anchor.jsx"
import Hamburger from "./Hamburger.jsx"

function NavMobile() {
    const [collapsed, setCollapsed] = useState(true);

    function handleBurgerClick() {
        setCollapsed(!collapsed)
    }


    return (<div className="nav-mobile">


            <div className="nav-mobile__wrap">
                <div onClick={handleBurgerClick} className={!collapsed ? "nav-mobile__page-overlay" : "collapse"}></div>
                <Hamburger onClick={handleBurgerClick}/>
                <div className="blocker" onClick={handleBurgerClick}></div>
                <div className={!collapsed ? "uncollapsed" : "collapse"}>
                    <div className="nav-mobile__anchors collapse">
                        <Anchor onClick={handleBurgerClick} anchor="#about"
                                className="nav__btn-nav nav-mobile__wrap--item" value="About me"/>
                        <Anchor onClick={handleBurgerClick} anchor="#work"
                                className="nav__btn-nav nav-mobile__wrap--item" value="My Work"/>
                        <Anchor onClick={handleBurgerClick} anchor="#technologies"
                                className="nav__btn-nav nav-mobile__wrap--item" value="Technologies"/>
                        <Anchor onClick={handleBurgerClick} anchor="#contact"
                                className="nav__btn-nav nav-mobile__wrap--item" value="Contact Me"/>
                        <Anchor className="nav__btn-nav nav-mobile__wrap--item-last" value="#Menu Space"/>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default NavMobile;



