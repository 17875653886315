import React from "react";
import Greeting from "./Greeting.jsx"
import Paragraph from "../../Paragraph.jsx";
import Card from "./Info-Card.jsx";
import SubtitleH3 from "../../SubTitle-H3.jsx";

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import InsertEmoticonRoundedIcon from '@material-ui/icons/InsertEmoticonRounded';
import ShowChartRoundedIcon from '@material-ui/icons/ShowChartRounded';
import MoneyRoundedIcon from '@material-ui/icons/MoneyRounded';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import EnhancedEncryptionRoundedIcon from '@material-ui/icons/EnhancedEncryptionRounded';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function InfoSection(){

    let infoSettings = {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2,
        swipeToSlide: true,
        speed: 750,
        autoplay: true,
        autoplaySpeed: 10000,
        centerMode: true,
        pauseOnHover: false,
        arrows: true,
        responsive: [
            {
              breakpoint: 850,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    
      };
    return (<div className="info">

    <div className="info__title">
        <div style={{marginBottom:"4rem"}} className="subtitle-border"><Greeting /></div>
        <SubtitleH3 text="Who I am" />
        <Paragraph text="Hi, I'm a British web designer and developer working remotely from Peru." /> 
        <Paragraph text="I have a simple desire to create beautiful, powerful websites that are fast, responsive and accessible." /> 
    </div>

    <div className="info__title">
    <SubtitleH3 text="How I can Help" />
        <Paragraph text="I will build your website based off your brand, ideals and business goals." /> 
        <Paragraph text="With well made landing pages, you can get a ton of traffic that can convert into a load of paying customers." /> 
    </div>

        {/*<div className="cards__wrap">*/}
        {/*    <Slider {...infoSettings}>*/}
        {/*        <Card svg={<InsertEmoticonRoundedIcon />} title="Websites that Count" text="First impressions count. Websites allow for a very strong impression and a good introduction for your business. I ensure that your branding identity and personal image is properly conveyed." />*/}
        {/*        <Card svg={<SearchRoundedIcon />} title="Search Engine Optimization (SEO)" text="I'll look at how your business can get better rankings with google search results. I'll optimise your website and do the keyword research. After all, more traffic means more clients." />*/}
        {/*        <Card svg={<DnsRoundedIcon />} title="Website Hosting and Support" text="I can get you all set up and hosted. I also provide an ongoing back-end service where I'll look after all the server side maintenance and updates." />*/}
        {/*        <Card svg={<MoneyRoundedIcon />} title="Optimise Your Income" text="Subscriptions, services, sales, ads? Whatever your income strategy is, I'll get you set up and running securely and smoothly." />*/}
        {/*        <Card svg={<ShowChartRoundedIcon />} title="The Power of Statistics" text="Website statistics will show user behaviour and trends, allowing finer control of user behaviour. You can also see the effectiveness of marketing campaigns or how long your users view a particular page." />*/}
        {/*        <Card svg={<EnhancedEncryptionRoundedIcon />} title="Earn Your Users’ Trust" text="Nobody wants to be hacked. Security of any website needs to be the number one priority for any business. It’s a business’s duty to ensure the privacy of users’ personal information, and protect your clasified business data." />*/}
        {/*    </Slider>*/}
        {/*</div>*/}
    </div>)
}

export default InfoSection;

