import React from "react";
import SubtitleH2 from "../../SubTitle-H2";

function IntroTitles() {
    return <>
        <div className={"head__title-section"}>

            <h1 className={"head__title-section--im-noah"}>Noah Gwynn</h1>

            <br/>

            <SubtitleH2 text={"Web & App Developer"}/>

        </div>
    </>
}

export default IntroTitles;