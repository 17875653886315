import React, {useState} from "react";
import {db} from "../../dataManagement/database/config/fbConfig";

function PopupForm(props) {
    //Textarea Character Count
    const [charCount, setCharCount] = useState(0);
    //Form Values Object
    const [contactForm, setContactForm] = useState({
        email: "",
        message: "",
    });
    const [status, setStatus] = useState({sending: false, sent: false, err: false})

    //Handle Input Change
    function handleChange(event) {
        const {name, value} = event.target;
        if (name === "message") {
            handleCount(value)
        }

        setContactForm((prevValue) => {
            return {...prevValue, [name]: value};
        })
    };

    function handleCount(value) {
        setCharCount(value.length)
    }

    const email = `<body>
    <h1 style="font-weight: 900; color: #0D5C42; margin-top: 20px; margin-bottom: 0;">
        You have received a message.</h1>
    <div style="padding:10px 0; margin-top: 0;">
        <h3 style="margin-bottom: 0;">Email:</h3>
        <p style="margin-top: 0;">${contactForm.email}</p>
        <h3 style="margin-bottom: 0;">Message:</h3>
        <p style="margin-top: 0;">${contactForm.message}</p>
    </div>
    </body>`

    //Handle Form Submit
    function submitHandler(event) {
        event.preventDefault();
        setStatus({sending: true, sent: false, err: false})
        db.collection('mail').add({
            to: "hello@noahgwynn.io",
            message: {
                subject: 'Message Received - noahgwynn.io',
                html: email,
            },
        })
            .then(() => {
                setStatus({sending: false, sent: true, err: false})
                setContactForm({email: "", message: ""})
            })
            .catch(err => setStatus({sending: false, sent: false, err: true}))
    }


    return (
        <div>
            <form id="contactForm" onSubmit={submitHandler} className="contact__form">
                <label htmlFor="name">Your Name</label>
                <input id="email" type="email" name="email" placeholder="Your E-mail *" maxLength="100"
                       onChange={handleChange} value={contactForm.email} className="contact__form--input"
                       required/>
                <label id="label" htmlFor="message">Your Message to me</label>
                <textarea id="message" name="message" placeholder="Your Message" maxLength="1500"
                          onChange={handleChange} value={contactForm.message} className="break contact__form--message4"
                          required/>
                <div className="contact__form--count-wrap"><p className="contact__form--count">{charCount}/1500</p>
                </div>


                <button id="formBtn" type="submit" className="btn btn-form" onClick={submitHandler}>Submit</button>
            </form>

            <p>{status.sent ? "Thank you for your message, I will be in contact shortly." :
                status.err ? "Oh! Something has gone wrong. Please try again." : ""}</p>
        </div>


    )
}

export default PopupForm;