import React from "react";
import Image from "../../Image.jsx";
import workCards from "./work-objects.js";


function WorkCard(props) {
    return (<div className="work__cards-wrapper">

            {workCards.map((card, index) => {
                const name = Object.values(card)[0];
                const info = Object.values(card)[1];
                const img = Object.values(card)[2];
                const alt = Object.values(card)[3];
                const href = Object.values(card)[4];

                return (<div key={index} className="work__card">
                        <a href={href} target="_blank" rel="noopener noreferrer">
                            <h3>{name}</h3>
                            <Image loading="lazy" src={img} alt={alt}/>
                            {/*<p>{info}</p>*/}
                        </a>
                    </div>

                )
            })}

        </div>


    );
};

export default WorkCard;