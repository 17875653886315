import React from "react";
import Anchor from "./Anchor.jsx"


function Nav(props) {

    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        const navbar = document.getElementById("navbar");
        if (navbar) {
            if (prevScrollpos > currentScrollPos) {
                navbar.style.top = "2rem"
            } else {
                navbar.style.top = "-70px";
            }
            prevScrollpos = currentScrollPos;
        }
    }


    return (<div id="navbar" className="nav">
            <Anchor anchor="#about" className="nav__btn-nav" value="About me"/>
            <Anchor anchor="#work" className="nav__btn-nav" value="My Work"/>
            <Anchor anchor="#technologies" className="nav__btn-nav" value="Technologies"/>
            <Anchor anchor="#testimonials" className="nav__btn-nav" value="Testimonial"/>
            <Anchor anchor="#contact" className="nav__btn-nav" value="Contact Me"/>

        </div>

    )
}

export default Nav;