import React, { useState } from "react";
import Paragraph from "../../Paragraph.jsx";
import SubtitleH2 from "../../SubTitle-H2.jsx";
import SubtitleH3 from "../../SubTitle-H3.jsx";
import CardButtons from "./Card-Button.jsx";
import {isMobile} from "react-device-detect";


function Tech(){
    const [info, setInfo] = useState(isMobile ? "Tap on the icons for descriptions" : "Hover on icons for descriptions")

    function handleInfo(newInfo){
        setInfo(newInfo)
    }

    return (
        <div className="tech">
            <div className="subtitle-border"><SubtitleH2 text="My Favourite Technologies" /></div>
            <SubtitleH3 text="" />
            <CardButtons handleInfo={handleInfo} /> 
            <Paragraph text={info} /> 
            
        </div>
    )
}

export default Tech;