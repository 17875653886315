import React from "react";

function Hamburger(props){
    return( 
            <button onClick={props.onClick} onFocusOut={props.onClick} className="nav nav__hamburger-menu btn"><span style={{position:"absolute", opacity:"0"}}>Menu</span>
                <svg xmlns="http://www.w3.org/2000/svg"  version="1.1">
                <circle className="nav__hamburger-menu--fill" cx="50" cy="50" r="49.8" style={{paintOrder:"markers fill stroke",strokeDashoffset:"0.9",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"0.2"}}/>
                <g transform="matrix(0.26145012,0,0,0.26145012,-1.0517193,1.1657617)">
                <path d="m72 186.3 246.4 0.9" style={{fill:"none",strokeLinecap:"round",strokeWidth:"24.4","stroke":"#BAD1BF"}}/>
                <path d="m72 115 246.4 0.9M72 257.6l246.4 0.9" style={{fill:"none",strokeLinecap:"round",strokeWidth:"24.4","stroke":"#BAD1BF"}}/>
                </g></svg>
            </button>

        
    )
}

export default Hamburger;



