import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import HomePage from "./Home-Page.jsx";
import Privacy from "./Privacy.jsx";
import CookieConsent from "react-cookie-consent";

function App() {


    const throb = document.getElementById("throbber").classList;
    throb.add("throbber-out");
    setTimeout(() => {
        throb.add("throbber-none")
    }, 1000);


    return (
        <div>
            <BrowserRouter>


                <div className="App">

                    <Switch>
                        <Route exact path="/" component={HomePage}/>
                        <Route exact path="/privacy" component={Privacy}/>
                        <Route path="/">
                            <Redirect to="/"/>
                        </Route>
                    </Switch>

                </div>


                <CookieConsent
                    location="bottom"
                    buttonText="Got it"
                    cookieName="noahgwynnCookieConsent"
                    style={{
                        background: "#111",
                        fontSize: "2rem",
                        fontFamily: "'Lato', sans-serif",
                        padding: "5px 90px 17px"
                    }}
                    buttonStyle={{background: "#0D5C42", color: "#f9ffff", fontSize: "2.2rem"}}
                    expires={365}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>

            </BrowserRouter>
        </div>

    )
}

export default App;