import React, {useState} from "react";
import SubtitleH2 from "../../SubTitle-H2.jsx";
import SubtitleH3 from "../../SubTitle-H3.jsx";
import Form from "./Form.jsx";

function Contact() {
    const [showForm, setShowForm] = useState(false);
    //response to form submit
    const [response, setResponse] = useState("");
    //Action
    const [act, setAct] = useState("");
    //Show Submit Btn
    const [showBtn, setShowBtn] = useState(false);


    return (
        <div className="contact">
            <div style={{marginBottom: "1.2rem"}} className="subtitle-border"><SubtitleH2 text="I'm all ears... "/>
            </div>
            <SubtitleH3 text="Tell me all about your project"/>

            {/*<div className="form-placeholder">*/}
            {/*    {<button type="button" className="form-placeholder__btn" name="message"*/}
            {/*             onClick={() => setShowForm(!showForm)}>Send me a message</button>}*/}
            {/*</div>*/}

            <Form act={act} showBtn={showBtn} showForm={showForm} setShowForm={setShowForm} setResponse={setResponse}/>

            <p className="form-response">{response}</p>
        </div>
    )
}

export default Contact;