import React from "react";
import svgObject from "./svg-objects.js"


function Svg (props){
      return (
        <div className={props.className}>{svgObject[props.name]}</div>
      );
  }

export default Svg;