import React from "react";
import { BrowserRouter} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


function Anchor(props){
    return(
        <BrowserRouter>
            <HashLink onClick={props.onClick} smooth to={props.anchor} className={"btn "+props.className}>{props.value}</HashLink>
        </BrowserRouter>
    )
}

export default Anchor;